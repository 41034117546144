import React from "react";
import error from "../../../assets/images/error-img.png";
import { TConversation } from "../../../store/features/chatSlice";
import dayjs from "dayjs";
import { NavLink } from "react-router-dom";
import { userName } from "../../../utils/enum";
import "./style.scss";

const ItemMessenger = ({
   sender,
   senderName,
   senderImg,
   receiver,
   receiverName,
   receiverImg,
   message,
   sendTime,
   read,
   keyChat,
   userLastMessage
}: TConversation & { keyChat: string }) => {
   return (
      <NavLink
         style={{ color: "#000000" }}
         className={(
            ({ isActive }) => isActive ? "item-conversation-active" : "item-conversation"
         )}
         to={`/messengers/${keyChat}`}
      >
         <img
            alt="avatar"
            src={senderImg ?? error} width={50} height={50}
            className="rounded-[50%] object-cover"
         />
         <div className="flex-1">
            <div className="w-[200px] font-semibold text-ellipsis overflow-hidden whitespace-nowrap">
               {senderName}
            </div>
            <div className="w-[200px] text-[12px] text-ellipsis overflow-hidden whitespace-nowrap">
               {`${userLastMessage === userName ? 'Bạn:' : ''} ${message}`}
            </div>
         </div>
         <div className="absolute right-[10px] top-[5px] text-[10px]">
            {dayjs(sendTime).format('HH:mm DD/MM/YYYY')}
         </div>
      </NavLink>
   )
};

export default ItemMessenger;