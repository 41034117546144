import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { loading, unLoading } from "./spinSlice";
import axiosClient from "../../axios/axiosClient";

export type TypeDataJob = {
  job_id: number;
  job_name: string;
  title: string;
  description: string;
  project_id: number | null;
  project_name: number | null;
  company_id: number;
  company_name: string;
  start_date: string;
  end_date: string;
  created_date: string;
  updated_date: string;
  state: 0 | 1 | 2 | null;
  reject_reason: string | null;
}

type stateJob = {
  data: TypeDataJob[];
  totalRecord: number;
  job: any;
}

const initialState: stateJob = {
  data: [],
  totalRecord: 0,
  job: null
}

export const getAllJob = createAsyncThunk(
  "job/get_all_job",
  async ({ companyName, jobName, state, offset, limit }: { companyName?: string, jobName?: string, state?: number, offset?: number, limit?: number }, thunkApi) => {
    thunkApi.dispatch(loading());
    try {
      const response = await axiosClient.get(`/admin/job/list?companyName=${companyName ?? ''}&jobName=${jobName ?? ''}&state=${state ?? ''}&offset=${offset}&limit=${limit}`);
      thunkApi.dispatch(unLoading());
      return response.data;
    } catch (error: any) {
      thunkApi.dispatch(unLoading());
      console.log(error)
      return thunkApi.rejectWithValue(error.response.data)
    }
  }
);

export const getJob = createAsyncThunk(
  "job/get_job",
  async ({ id }: { id?: string }, thunkApi) => {
    thunkApi.dispatch(loading());
    try {
      const response = await axiosClient.get(`/admin/job?jobId=${id}`);
      thunkApi.dispatch(unLoading());
      return response.data;
    } catch (error: any) {
      thunkApi.dispatch(unLoading());
      console.log(error)
      return thunkApi.rejectWithValue(error.response.data)
    }
  }
);

export const confirmJob = createAsyncThunk(
  "job/reject_job",
  async (params: { id?: number; state: 0 | 1 | 2; reason: string; }, thunkApi) => {
    thunkApi.dispatch(loading());
    try {
      const response = await axiosClient.post(`/admin/job/change`, params);
      thunkApi.dispatch(unLoading());
      return response.data;
    } catch (error: any) {
      thunkApi.dispatch(unLoading());
      console.log(error)
      return thunkApi.rejectWithValue(error.response.data)
    }
  }
);

const jobSlice = createSlice({
  name: "job",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAllJob.fulfilled, (state, action) => {
        state.data = action.payload.data?.jobs;
        state.totalRecord = action.payload.data?.total_record;
      })
      .addCase(getJob.fulfilled, (state, action) => {
        state.job = action.payload.data;
      })
  },
});

export default jobSlice.reducer;