import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/hook";
import { confirmProject, getProject } from "../../../store/features/projectSlice";
import TitlePage from "../../../components/atoms/titlePage";
import { Button, Carousel, Image, Modal, Tag, message } from 'antd';
import errorImage from "../../../assets/images/error-img.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faChevronCircleLeft, faChevronCircleRight, faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Epath } from "../../../utils/Epath";
import { ColorStateProject, LabelStateProject, StateProject } from "../../../utils/enum";
import TextArea from "antd/es/input/TextArea";
import { CarouselRef } from "antd/es/carousel";

const ProjectManagerUpdate = () => {
    const key = "messlogin";
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { project_id } = useParams();
    const refCarousel = useRef<CarouselRef>(null);
    const [rejectReason, setRejectReason] = useState("");
    const [errorReject, setErrorReject] = useState(false);
    const [modalReject, setModalReject] = useState(false);
    const project = useAppSelector(state => state.projectSlice.project);

    const getDetailProject = () => {
        dispatch(getProject({ id: project_id }))
    };

    const closeModalConfirm = () => {
        setModalReject(false);
    };

    const onChangeReason = (value: string) => {
        setErrorReject(!(value.length > 0));
        setRejectReason(value);
    };

    const showModalConfirm = () => {
        setRejectReason("");
        setErrorReject(false);
        setModalReject(true);
    };

    const onChangeCarousel = (type: "next" | "prev") => {
        if (refCarousel.current) {
            type === "next" ? refCarousel.current?.next() : refCarousel.current.prev();
        }
    }

    const onSubmitConfirm = (type: "reject" | "approve") => {
        const params = {
            id: project?.project_id,
            state: type === "reject" ? StateProject.STATE_REJECT : StateProject.STATE_APPROVE,
            reason: rejectReason
        };
        let error = false;
        if (type === "reject") {
            if (!(rejectReason.length > 0)) {
                setErrorReject(true);
                error = true;
            }
        }
        if (!error) {
            dispatch(confirmProject(params)).then((res) => {
                message.open({ type: "success", content: res.payload.message, key, duration: 2 });
                closeModalConfirm();
                getDetailProject();
            }).catch((error) => {
                message.open({ type: "error", content: error.message, key, duration: 2 });
            })
        }
    };

    useEffect(() => {
        getDetailProject();
    }, []);

    return (
        <div className="h-full">
            <div className="flex items-center">
                <h2 className="ml-[20px]">
                    <FontAwesomeIcon icon={faArrowLeft} className="cursor-pointer" onClick={() => navigate(Epath.HOME)} />
                </h2>
                <TitlePage title="Chi tiết bài đăng" />
            </div>
            <div className="box-border" style={{ height: "calc(100% - 106px)" }}>
                <div className="h-full overflow-auto box-border">
                    <div className="flex w-full gap-[20px] px-[16px] box-border">
                        <div className="w-[40%]">
                            <h3>Chủ đầu tư</h3>
                            <div className="mt-[30px] flex flex-col gap-[10px]">
                                <Image
                                    width={250}
                                    src={project?.company?.image}
                                    fallback={errorImage}
                                />
                                <div><span className="font-semibold">Tên chủ đầu tư:</span> {project?.company?.name}</div>
                                <div><span className="font-semibold">Địa chỉ:</span> {project?.company?.address}</div>
                                <div><span className="font-semibold">Tax Code:</span> {project?.company?.tax_code}</div>
                                <div><span className="font-semibold">Hotline:</span> {project?.company?.hotline}</div>
                                <div><span className="font-semibold">Mô tả:</span> {project?.company?.description}</div>
                            </div>
                        </div>
                        <div className="w-[60%]">
                            <h3 className="flex justify-between items-center">
                                Bài đăng
                                <span>
                                    Trạng thái duyệt bài:
                                    <span className="px-[8px] py-[2px] rounded-[6px] text-[#ffffff] ml-[10px] text-[13px]"
                                        style={{
                                            backgroundColor: project && project.state !== undefined
                                                ? (project.state === StateProject.STATE_APPROVE
                                                    ? ColorStateProject.STATE_APPROVE
                                                    : project.state === StateProject.STATE_PROCESSING
                                                        ? ColorStateProject.STATE_PROCESSING
                                                        : ColorStateProject.STATE_REJECT)
                                                : ColorStateProject.STATE_PROCESSING
                                        }}
                                    >
                                        {project && project.state !== undefined
                                            ? (project.state === StateProject.STATE_APPROVE
                                                ? LabelStateProject.STATE_APPROVE
                                                : project.state === StateProject.STATE_PROCESSING
                                                    ? LabelStateProject.STATE_PROCESSING
                                                    : LabelStateProject.STATE_REJECT)
                                            : LabelStateProject.STATE_PROCESSING 
                                        }
                                    </span>
                                </span>
                            </h3>
                            {project?.reject_reason
                                && <span>
                                    <span className="font-semibold italic text-[red] text-[12px]">Lý do bị từ chối: </span>
                                    <span className="text-[13px]">{project?.reject_reason}</span>
                                </span>
                            }
                            <div className="mt-[30px] flex flex-col gap-[10px] box-border">
                                <div className="flex gap-[10px]">
                                    <div className="w-[400px] box-border relative">
                                        {project?.images.length > 0
                                            ? <Carousel autoplay ref={refCarousel}>
                                                {project.images.map((image: { name: string; link: string }) => (
                                                    <div key={image.name}>
                                                        <div className="w-[400px] h-[160px] flex justify-center">
                                                            <Image
                                                                width={160}
                                                                src={`${process.env.REACT_APP_BASE_URL}/storage/download/${image.link}`}
                                                                fallback={errorImage}
                                                            />
                                                        </div>
                                                    </div>
                                                ))}
                                            </Carousel>
                                            : <div className="w-full h-[160px] border border-dashed rounded-[6px] border-orange-400 flex justify-center items-center">
                                                Không có dữ liệu ảnh
                                            </div>
                                        }
                                        <FontAwesomeIcon onClick={() => onChangeCarousel("prev")} className="absolute left-0 top-[50%]" fontSize={24} icon={faChevronCircleLeft} />
                                        <FontAwesomeIcon onClick={() => onChangeCarousel("next")} className="absolute right-0 top-[50%]" fontSize={24} icon={faChevronCircleRight} />
                                    </div>
                                    <div className="flex-1 border border-solid border-orange-400 rounded-[6px] box-border p-[5px] h-[160px]">
                                        <div className="flex flex-col">
                                            <p className="h-[30px] text-nowrap italic text-[12px] font-semibold">Các file dự án:</p>
                                            <div className="w-full flex-1">
                                                <div className="h-[120px] overflow-auto">
                                                    <div className="flex gap-[10px] flex-wrap">
                                                        {project?.files.length > 0 &&
                                                            project?.files.map((file: { name: string; link: string }) => (
                                                                <a
                                                                    href={`${process.env.REACT_APP_BASE_URL}/storage/download/${file.link}`}
                                                                    key={file.link}
                                                                    download={file.name}
                                                                    target="_blank"
                                                                    className="border border-solid border-blue-500 rounded-[6px] px-[5px]"
                                                                >{file.name}</a>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div><span className="font-semibold">Tên dự án:</span> {project?.project_name}</div>
                                <div><span className="font-semibold">Ngày bắt đầu:</span> {project?.start_date}</div>
                                <div><span className="font-semibold">Ngày kết thúc:</span> {project?.end_date}</div>
                                <div><span className="font-semibold">Mô tả dự án:</span> {project?.description}</div>
                                <div><span className="font-semibold">Đánh giá:</span> {project?.star}</div>
                                <div><span className="font-semibold">Trạng thái:</span> {project?.status}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="h-[56px] flex justify-end items-center gap-[20px] px-[20px]">
                {project?.state !== StateProject.STATE_REJECT && <Button type="primary" danger onClick={showModalConfirm}>Từ chối</Button>}
                {project?.state !== StateProject.STATE_APPROVE && <Button type="primary" onClick={() => onSubmitConfirm("approve")}>Chấp thuận</Button>}
            </div>
            <Modal
                title="Từ chối bài đăng"
                open={modalReject}
                onCancel={closeModalConfirm}
                onOk={() => onSubmitConfirm("reject")}
                width={400}
                okText="Lưu"
                cancelText="Hủy bỏ"
            >
                <h4 className="text-center my-[30px]">Vui lòng nhập lý do từ chối bài đăng</h4>
                <TextArea
                    value={rejectReason}
                    rows={4}
                    placeholder="Lý do..."
                    status={errorReject ? 'error' : undefined}
                    onChange={(e) => { onChangeReason(e.target.value) }}
                />
                {errorReject && <p className='text-[red] mt-[4px] text-[12px]'>Yêu cầu nhập lý do</p>}
            </Modal>
        </div>
    )
};

export default ProjectManagerUpdate;