import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import logoFindJob from "../../../assets/images/logo.png";
import Field from "../../../components/common/input";
import { LoginForm } from "../../../utils/typeForm";
import { Button, message } from "antd";
import { useAppDispatch, useAppSelector } from "../../../store/hook";
import { login } from "../../../store/features/authSlice";
import { useNavigate } from "react-router-dom";
import { Epath } from "../../../utils/Epath";
import { localSetItem } from "../../../utils/localstorage";

const Login = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const entity = useAppSelector(state => state.authSlice.entity);
    const error = useAppSelector(state => state.authSlice.error);
    const methods = useForm<LoginForm>({
        mode: 'onSubmit', criteriaMode: 'firstError', reValidateMode: 'onChange'
    });
    const key = "messlogin"

    const onLogin = (value: LoginForm) => {
        const params = {
            user_name: value.user_name,
            password: value.password
        }
        dispatch(login(params));
    }

    useEffect(() => {
        if (entity) {
            localSetItem("token", entity.data?.token);
            localSetItem("refresh_token", entity.data?.refresh_token);
            localSetItem("user", JSON.stringify(entity.data?.user_info));
            message.open({ type: "success", content: "Đăng nhập thành công!", key, duration: 2 });
            setTimeout(() => {navigate(Epath.HOME)}, 2000);
        }
    }, [entity, navigate]);

    useEffect(() => {
        if (error) {
            message.open({ type: "error", content: error.message, key, duration: 2 });
        }
    }, [error, navigate]);

    return (
        <div className="w-100 mt-[80px] flex flex-col items-center">
            <div className="xs:w-[350px] xm:w-[390px] box-border rounded-[8px] shadow-2xl flex flex-col items-center pt-[50px] py-[20px] px-[20px]">
                <img
                    className="xs:w-[80px] xs:h-[80px]"
                    alt="logo findjob"
                    src={logoFindJob}
                />
                <h2 className="text-center mt-[20px]">Đăng nhập vào hệ thống</h2>
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onLogin)} className="mt-[30px] w-full">
                        <Field
                            name="user_name"
                            type="text"
                            label="Tên đăng nhập"
                            labelClass="required"
                            placeholder="Tên đăng nhập"
                            rules={{
                                required: {
                                    value: true,
                                    message: "Vui lòng nhập tên đăng nhập"
                                }
                            }}
                        />
                        <Field
                            name="password"
                            type="password"
                            label="Mật khẩu"
                            labelClass="required"
                            autoComplete="none"
                            placeholder="Mật khẩu"
                            rules={{
                                required: {
                                    value: true,
                                    message: "Vui lòng nhập mật khẩu"
                                }
                            }}
                        />
                        <Button
                            className="h-[40px] mt-[20px]"
                            type="primary"
                            htmlType="submit"
                            block
                        >
                            Đăng nhập
                        </Button>
                    </form>
                </FormProvider>
            </div>
        </div>
    )
};

export default Login;