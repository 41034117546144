import { configureStore } from "@reduxjs/toolkit";
import spinSlice from "./features/spinSlice";
import authSlice from "./features/authSlice";
import projectSlice from "./features/projectSlice";
import jobSlice from "./features/jobSlice";
import productSlice from "./features/productSlice";
import chatSlice from "./features/chatSlice";

export const store = configureStore({
    reducer: {
        // Add reducer...
        spinSlice,
        authSlice,
        projectSlice,
        jobSlice,
        productSlice,
        chatSlice,
    }
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch;