import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { loading, unLoading } from "./spinSlice";
import axiosClient from "../../axios/axiosClient";

export type TypeDataProduct = {
  product_id: number;
  product_name: string;
  product_code: string;
  product_type: 0 | 1;
  product_category_id: number;
  product_category_name: string | null;
  brand: string | null;
  price: number | null;
  quantity: number | null;
  unit: number;
  unit_name: string | null;
  description: string | null;
  store_id: number;
  store_name: string | null;
  hotline: string | null;
  star: number;
  created_date: string;
  updated_date: string;
  state: 0 | 1 | 2 | null;
  reject_reason: string | null;
}

type stateProduct = {
  data: TypeDataProduct[];
  totalRecord: number;
  product: any;
}

const initialState: stateProduct = {
  data: [],
  totalRecord: 0,
  product: null
}

export const getAllProduct = createAsyncThunk(
  "product/get_all_product",
  async ({ storeName, productName, state, offset, limit }: { storeName?: string, productName?: string, state?: number, offset?: number, limit?: number }, thunkApi) => {
    thunkApi.dispatch(loading());
    try {
      const response = await axiosClient.get(`/admin/product/list?storeName=${storeName ?? ''}&productName=${productName ?? ''}&state=${state ?? ''}&offset=${offset}&limit=${limit}`);
      thunkApi.dispatch(unLoading());
      return response.data;
    } catch (error: any) {
      thunkApi.dispatch(unLoading());
      console.log(error)
      return thunkApi.rejectWithValue(error.response.data)
    }
  }
);

export const getProduct = createAsyncThunk(
  "product/get_product",
  async ({ id }: { id?: string }, thunkApi) => {
    thunkApi.dispatch(loading());
    try {
      const response = await axiosClient.get(`/admin/product?productId=${id}`);
      thunkApi.dispatch(unLoading());
      return response.data;
    } catch (error: any) {
      thunkApi.dispatch(unLoading());
      console.log(error)
      return thunkApi.rejectWithValue(error.response.data)
    }
  }
);

export const confirmProduct = createAsyncThunk(
  "product/reject_product",
  async (params: { id?: number; state: 0 | 1 | 2; reason: string; }, thunkApi) => {
    thunkApi.dispatch(loading());
    try {
      const response = await axiosClient.post(`/admin/product/change`, params);
      thunkApi.dispatch(unLoading());
      return response.data;
    } catch (error: any) {
      thunkApi.dispatch(unLoading());
      console.log(error)
      return thunkApi.rejectWithValue(error.response.data)
    }
  }
);

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAllProduct.fulfilled, (state, action) => {
        state.data = action.payload.data?.products;
        state.totalRecord = action.payload.data?.total_record;
      })
      .addCase(getProduct.fulfilled, (state, action) => {
        state.product = action.payload.data.product;
      })
  },
});

export default productSlice.reducer;