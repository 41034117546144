import React from "react";
import { TMessage } from "../../../store/features/chatSlice";
import { userName } from "../../../utils/enum";
import dayjs from "dayjs";
import "./style.scss";

const ItemChat = ({
   id,
   message,
   sendTime,
   sender,
   read,
}: TMessage) => {
   return (
      <div className={`w-full flex ${sender === userName ? 'flex-row-reverse' : ''}`}>
         <div className={`container-item-chat flex py-[5px] items-center gap-2 ${sender === userName ? 'flex-row-reverse' : ''}`}>
            <div className={`py-[5px] px-[10px] text-[12px] rounded-[30px] whitespace-pre-wrap max-w-[500px] text-white
            ${sender === userName ? 'bg-[orange]' : 'bg-zinc-700'}`}
            >
               {message}
            </div>
            <p className="send-time text-[11px]">{dayjs(sendTime).format("HH:mm DD/MM/YYYY")}</p>
         </div>
      </div>
   )
};

export default ItemChat;