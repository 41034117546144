import { RouterProvider, createBrowserRouter } from "react-router-dom";
import PrivateRouter from "./PrivateRouter";
import AdminLayout from "../layout";
import { Epath } from "../utils/Epath";
import Profile from "../pages/profile";
import Login from "../pages/auth/login";
import SuspenseFallback from "../components/atoms/suspenseFallback";
import { useAppSelector } from "../store/hook";
import ProjectManager from "../pages/project-manager";
import ProjectManagerUpdate from "../pages/project-manager/project-manager-update";
import JobManager from "../pages/job-manager";
import ProductManager from "../pages/product-manager";
import JobManagerUpdate from "../pages/job-manager/job-manager-update";
import ProductManagerUpdate from "../pages/product-manager/product-manager-update";
import Messengers from "../pages/messengers";

const RootRouter = () => {
    const isLoading = useAppSelector(state => state.spinSlice.value);

    const router = createBrowserRouter([
        {
            path: "/",
            element: (
                <PrivateRouter>
                    <AdminLayout />
                </PrivateRouter>
            ),
            children: [
                {
                    path: Epath.HOME,
                    element: <ProjectManager />
                },
                {
                    path: `${Epath.PROJECT_MANAGER_UPDATE}/:project_id`,
                    element: <ProjectManagerUpdate />
                },
                {
                    path: Epath.JOB,
                    element: <JobManager />
                },
                {
                    path: `${Epath.JOB_MANAGER_UPDATE}/:job_id`,
                    element: <JobManagerUpdate />
                },
                {
                    path: Epath.PRODUCT,
                    element: <ProductManager />
                },
                {
                    path: `${Epath.PRODUCT_MANAGER_UPDATE}/:product_id`,
                    element: <ProductManagerUpdate />
                },
                {
                    path: Epath.MESSENGERS,
                    element: <Messengers />
                },
                {
                    path: `${Epath.MESSENGERS}/:conversation_id`,
                    element: <Messengers />
                },
                {
                    path: Epath.PROFILE,
                    element: <Profile />
                }
            ]
        },
        {
            path: Epath.LOGIN,
            element: <Login />
        }
    ]);

    return (
        <>
            <RouterProvider
                router={router}
                fallbackElement={<SuspenseFallback />}
            />
            {isLoading && <SuspenseFallback />}
        </>
    )
}

export default RootRouter;
