import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { loading, unLoading } from "./spinSlice";
import axiosClient from "../../axios/axiosClient";

export type TypeDataProject = {
    project_id: number;
    project_name: string;
    company_id: number;
    company_name: string;
    start_date: string;
    end_date: string;
    created_date: string;
    updated_date: string;
    state: 0 | 1 | 2 | null;
    reject_reason: string | null;
};

type stateProject = {
    data: TypeDataProject[];
    totalRecord: number;
    project: any;
};

const initialState: stateProject = {
    data: [],
    totalRecord: 0,
    project: null
}

export const getAllProject = createAsyncThunk(
    "project/get_all_project",
    async ({ companyName, projectName, state, offset, limit }: { companyName?: string, projectName?: string, state?: number, offset?: number, limit?: number }, thunkApi) => {
        thunkApi.dispatch(loading());
        try {
            const response = await axiosClient.get(`/admin/project/list?companyName=${companyName ?? ''}&projectName=${projectName ?? ''}&state=${state ?? ''}&offset=${offset}&limit=${limit}`);
            thunkApi.dispatch(unLoading());
            return response.data;
        } catch (error: any) {
            thunkApi.dispatch(unLoading());
            console.log(error)
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const getProject = createAsyncThunk(
    "project/get_project",
    async ({ id }: { id?: string }, thunkApi) => {
        thunkApi.dispatch(loading());
        try {
            const response = await axiosClient.get(`/admin/project?projectId=${id}`);
            thunkApi.dispatch(unLoading());
            return response.data;
        } catch (error: any) {
            thunkApi.dispatch(unLoading());
            console.log(error)
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const confirmProject = createAsyncThunk(
    "project/reject_project",
    async (params: { id?: number; state: 0 | 1 | 2; reason: string; }, thunkApi) => {
        thunkApi.dispatch(loading());
        try {
            const response = await axiosClient.post(`/admin/project/change`, params);
            thunkApi.dispatch(unLoading());
            return response.data;
        } catch (error: any) {
            thunkApi.dispatch(unLoading());
            console.log(error)
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

const projectSlice = createSlice({
    name: "project",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getAllProject.fulfilled, (state, action) => {
                state.data = action.payload.data?.projects;
                state.totalRecord = action.payload.data?.total_record;
            })
            .addCase(getProject.fulfilled, (state, action) => {
                state.project = action.payload.data;
            })
    },
});

export default projectSlice.reducer;