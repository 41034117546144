export const localGetItem = (item: string) => {
    if (item) {
        return localStorage.getItem(item);
    }
}

export const localSetItem = (item: string, value: string) => {
    localStorage.setItem(item, value);
}

export const localDeleteItem = (item: string) => {
    localStorage.removeItem(item);
}

export const localClearLocalStorage = () => {
    localStorage.clear();
}