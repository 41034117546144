import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { loading, unLoading } from "./spinSlice";
import axiosChat from "../../axios/axiosChat";

export type TConversation = {
   sender: string;
   senderName: string;
   senderImg: string | null;
   receiver: string;
   receiverName: string;
   receiverImg: string | null;
   key: string;
   message: string;
   sendTime: string;
   read: boolean;
   userLastMessage: string;
};

export type TMessage = {
   id: number;
   // key: string;
   message: string;
   sendTime: string;
   sender: string;
   read: boolean;
};

export type TChatSlice = {
   userChat: TConversation[];
   listMessage: TMessage[];
};

const initialState: TChatSlice = {
   userChat: [],
   listMessage: [],
};

export const getUserChat = createAsyncThunk(
   'get-user-chat',
   async (_, thunkApi) => {
      thunkApi.dispatch(loading());
      try {
         const response = await axiosChat.get("/sender-receiver/user-inboxed?sender=adminAccount");
         thunkApi.dispatch(unLoading());
         return response.data;
      } catch (error: any) {
         thunkApi.dispatch(unLoading());
         console.log(error)
         return thunkApi.rejectWithValue(error.response.data)
      }
   }
);

export const getListMessage = createAsyncThunk(
   'get-conversation-user',
   async (key: string, thunkApi) => {
      try {
         const response = await axiosChat.get(`/sender-receiver/old_message?key=${key}`);
         return response.data;
      } catch (error: any) {
         console.log(error);
         return thunkApi.rejectWithValue(error.response.data);
      }
   }
);

// lấy thông tin phòng
export const getRoom = createAsyncThunk(
   'get-room',
   async ({sender, receiver, key}: {sender: string; receiver: string; key: string}, thunkApi) => {
      try {
         const response = await axiosChat.get(`/sender-receiver/detail?sender=${sender}&receiver=${receiver}&key=${key}`);
         return response.data;
      } catch (error: any) {
         console.log(error);
         return thunkApi.rejectWithValue(error.response.data);
      }
   }  
);

// gửi tin nhắn
export const sendMessage = () => createAsyncThunk(
   'send-message', 
   async () => {

   }
);

const chatSlice = createSlice({
   name: "chat",
   initialState,
   reducers: {},
   extraReducers(builder) {
      builder
         .addCase(getUserChat.fulfilled, (state, action) => {
            state.userChat = action.payload;
         })
         .addCase(getListMessage.pending , (state) => {
            state.listMessage = [];
         })
         .addCase(getListMessage.fulfilled, (state, action) => {
            state.listMessage = action.payload;
         })
   },
});

export default chatSlice.reducer