import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/hook";
import TitlePage from "../../../components/atoms/titlePage";
import { Button, Carousel, Image, Modal, Tag, message } from 'antd';
import errorImage from "../../../assets/images/error-img.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faChevronCircleLeft, faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import { Epath } from "../../../utils/Epath";
import { ColorStateProject, LabelStateProject, ProductType, StateProject } from "../../../utils/enum";
import TextArea from "antd/es/input/TextArea";
import { CarouselRef } from "antd/es/carousel";
import { confirmProduct, getProduct } from "../../../store/features/productSlice";

const ProductManagerUpdate = () => {
  const key = "messlogin";
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { product_id } = useParams();
  const refCarousel = useRef<CarouselRef>(null);
  const [rejectReason, setRejectReason] = useState("");
  const [errorReject, setErrorReject] = useState(false);
  const [modalReject, setModalReject] = useState(false);
  const product = useAppSelector(state => state.productSlice.product);

  const getDetailProduct = () => {
    dispatch(getProduct({ id: product_id }))
  };

  const closeModalConfirm = () => {
    setModalReject(false);
  };

  const onChangeReason = (value: string) => {
    setErrorReject(!(value.length > 0));
    setRejectReason(value);
  };

  const showModalConfirm = () => {
    setRejectReason("");
    setErrorReject(false);
    setModalReject(true);
  };

  const onChangeCarousel = (type: "next" | "prev") => {
    if (refCarousel.current) {
      type === "next" ? refCarousel.current?.next() : refCarousel.current.prev();
    }
  }

  const onSubmitConfirm = (type: "reject" | "approve") => {
    const params = {
      id: product?.product_id,
      state: type === "reject" ? StateProject.STATE_REJECT : StateProject.STATE_APPROVE,
      reason: rejectReason
    };
    let error = false;
    if (type === "reject") {
      if (!(rejectReason.length > 0)) {
        setErrorReject(true);
        error = true;
      }
    }
    if (!error) {
      dispatch(confirmProduct(params)).then((res) => {
        message.open({ type: "success", content: "Cập nhật sản phẩm thành công", key, duration: 2 });
        closeModalConfirm();
        getDetailProduct();
      }).catch((error) => {
        message.open({ type: "error", content: error.message, key, duration: 2 });
      })
    }
  };

  useEffect(() => {
    getDetailProduct();
  }, []);

  return (
    <div className="h-full">
      <div className="flex items-center">
        <h2 className="ml-[20px]">
          <FontAwesomeIcon icon={faArrowLeft} className="cursor-pointer" onClick={() => navigate(Epath.PRODUCT)} />
        </h2>
        <TitlePage title="Chi tiết sản phẩm" />
      </div>
      <div className="box-border px-[16px]" style={{ height: "calc(100% - 106px)" }}>
        <div className="h-full overflow-auto box-border">
          <div className="flex w-full gap-[20px] px-[16px] box-border">
            <div className="w-[40%]">
              <h3>Cửa hàng</h3>
              <div className="mt-[30px] flex flex-col gap-[10px]">
                <Image
                  width={250}
                  src={product?.store_image}
                  fallback={errorImage}
                />
                <div><span className="font-semibold">Tên cửa hàng:</span> {product?.store_name}</div>
                <div><span className="font-semibold">Hotline:</span> {product?.hotline}</div>
              </div>
            </div>
            <div className="w-[60%]">
              <h3 className="flex justify-between items-center">
                Bài đăng
                <span>
                  Trạng thái duyệt bài:
                  <span className="px-[8px] py-[2px] rounded-[6px] text-[#ffffff] ml-[10px] text-[13px]"
                    style={{
                      backgroundColor: product && product.state !== undefined
                        ? (product.state === StateProject.STATE_APPROVE
                          ? ColorStateProject.STATE_APPROVE
                          : product.state === StateProject.STATE_PROCESSING
                            ? ColorStateProject.STATE_PROCESSING
                            : ColorStateProject.STATE_REJECT)
                        : ColorStateProject.STATE_PROCESSING
                    }}
                  >
                    {product && product.state !== undefined
                      ? (product.state === StateProject.STATE_APPROVE
                        ? LabelStateProject.STATE_APPROVE
                        : product.state === StateProject.STATE_PROCESSING
                          ? LabelStateProject.STATE_PROCESSING
                          : LabelStateProject.STATE_REJECT)
                      : LabelStateProject.STATE_PROCESSING
                    }
                  </span>
                </span>
              </h3>
              {product?.reject_reason
                && <span>
                  <span className="font-semibold italic text-[red] text-[12px]">Lý do bị từ chối: </span>
                  <span className="text-[13px]">{product?.reject_reason}</span>
                </span>
              }
              <div className="mt-[30px] flex flex-col gap-[10px] box-border">
                <div className="flex gap-[10px]">
                  <div className="w-[400px] box-border relative">
                    {product?.product_images.length > 0
                      ? <Carousel autoplay ref={refCarousel}>
                        {product.product_images.map((image: string) => (
                          <div key={image}>
                            <div className="w-[400px] h-[160px] flex justify-center">
                              <Image
                                width={160}
                                src={`${process.env.REACT_APP_BASE_URL}/storage/download/${image}`}
                                fallback={errorImage}
                              />
                            </div>
                          </div>
                        ))}
                      </Carousel>
                      : <div className="w-full h-[160px] border border-dashed rounded-[6px] border-orange-400 flex justify-center items-center">
                        Không có dữ liệu ảnh
                      </div>
                    }
                    <FontAwesomeIcon onClick={() => onChangeCarousel("prev")} className="absolute left-0 top-[50%]" fontSize={24} icon={faChevronCircleLeft} />
                    <FontAwesomeIcon onClick={() => onChangeCarousel("next")} className="absolute right-0 top-[50%]" fontSize={24} icon={faChevronCircleRight} />
                  </div>
                </div>
                <div><span className="font-semibold">Mã sản phẩm: </span> {product?.product_code}</div>
                <div><span className="font-semibold">Tên sản phẩm: </span> {product?.product_name}</div>
                <div>
                  <span className="font-semibold">Loại sản phẩm: </span>
                  {product?.product_type === ProductType.VLXD.value ? ProductType.VLXD.label : ProductType.TBXD.label}
                </div>
                <div><span className="font-semibold">Thương hiệu: </span> {product?.brand}</div>
                <div><span className="font-semibold">Giá sản phẩm: </span> {product?.price?.toLocaleString()} VNĐ</div>
                <div><span className="font-semibold">Số lượng trong kho: </span> {product?.quantity}</div>
                <div><span className="font-semibold">Mô tả sản phẩm: </span> {product?.description}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="h-[56px] flex justify-end items-center gap-[20px] px-[20px]">
        {product?.state !== StateProject.STATE_REJECT && <Button type="primary" danger onClick={showModalConfirm}>Từ chối</Button>}
        {product?.state !== StateProject.STATE_APPROVE && <Button type="primary" onClick={() => onSubmitConfirm("approve")}>Chấp thuận</Button>}
      </div>
      <Modal
        title="Từ chối sản phẩm"
        open={modalReject}
        onCancel={closeModalConfirm}
        onOk={() => onSubmitConfirm("reject")}
        width={400}
        okText="Lưu"
        cancelText="Hủy bỏ"
      >
        <h4 className="text-center my-[30px]">Vui lòng nhập lý do từ chối sản phẩm</h4>
        <TextArea
          value={rejectReason}
          rows={4}
          placeholder="Lý do..."
          status={errorReject ? 'error' : undefined}
          onChange={(e) => { onChangeReason(e.target.value) }}
        />
        {errorReject && <p className='text-[red] mt-[4px] text-[12px]'>Yêu cầu nhập lý do</p>}
      </Modal>
    </div>
  )
};

export default ProductManagerUpdate;