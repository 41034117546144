import { Table, TablePaginationConfig, TableProps, Tag, Pagination, Button, Tooltip, PaginationProps, Modal, message, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import TitlePage from "../../components/atoms/titlePage";
import { TypeDataProject, getAllProject, confirmProject } from "../../store/features/projectSlice";
import { LabelStateProject, StateProject } from "../../utils/enum";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { faMagnifyingGlass, faSort } from "@fortawesome/free-solid-svg-icons";
import "./style.scss";
import { useAppDispatch, useAppSelector } from "../../store/hook";
import { useNavigate } from "react-router-dom";
import { Epath } from "../../utils/Epath";
import TextArea from "antd/es/input/TextArea";
import { useDebounce } from "../../utils/customHook/useDebounce";
import CustomPopover from "../../components/common/popover/customPopover";

export type TableParams = {
    pagination?: TablePaginationConfig;
}

const ProjectManager = () => {
    const key = "messlogin";
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const projectSlice = useAppSelector(state => state.projectSlice);
    const [modalReject, setModalReject] = useState(false);
    const [errorReject, setErrorReject] = useState(false);
    const [rejectReason, setRejectReason] = useState<string>("");
    const [projectSelect, setProjectSelect] = useState<TypeDataProject>();
    const [type, setType] = useState<"approve" | "reject">("approve");
    const [searchCompany, setSearchCompany] = useState("");
    const [searchProject, setSearchProject] = useState("");
    const [searchState, setSearchState] = useState();
    const debouncedCompany = useDebounce<string>(searchCompany, 500);
    const debouncedProject = useDebounce<string>(searchProject, 500);
    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            pageSize: 10
        }
    });

    const showModalConfirm = (record: TypeDataProject, type: "approve" | "reject") => {
        if (type === "reject") {
            setRejectReason("");
            setErrorReject(false);
        }
        setType(type);
        setProjectSelect(record);
        setModalReject(true);
    };

    const closeModalConfirm = () => {
        setModalReject(false);
    }

    const columns: TableProps['columns'] = [
        {
            title: (
                <div className="flex justify-between items-center">
                    STT <FontAwesomeIcon icon={faSort} />
                </div>
            ),
            dataIndex: "stt",
            key: "stt",
            width: "10%",
            render: (_, record, index) => index + 1
        },
        {
            title: (
                <div className="flex justify-between items-center">
                    Ngày đăng <FontAwesomeIcon icon={faSort} />
                </div>
            ),
            dataIndex: "created_date",
            key: "created_date",
            align: "center",
            width: "10%"
        },
        {
            title: (
                <div className="flex justify-between items-center">
                    Chủ đầu tư
                    <CustomPopover
                        content={<>
                            <Input
                                placeholder="Tên chủ đầu tư"
                                name="searchCompany"
                                allowClear
                                onChange={(e) => setSearchCompany(e.target.value)}
                            />
                        </>}
                        title="Tìm kiếm theo chủ đầu tư"
                        icon={faMagnifyingGlass}
                        placement="bottomRight"
                    />
                </div>
            ),
            dataIndex: "company_name",
            key: "company_name",
            width: "20%",
            ellipsis: {
                showTitle: false,
            },
        },
        {
            title: (
                <div className="flex justify-between items-center">
                    Tên dự án
                    <CustomPopover
                        content={<>
                            <Input
                                placeholder="Tên dự án"
                                name="searchProject"
                                allowClear
                                onChange={(e) => setSearchProject(e.target.value)}
                            />
                        </>}
                        title="Tìm kiếm theo tên dự án"
                        icon={faMagnifyingGlass}
                        placement="bottomRight"
                    />
                </div>
            ),
            dataIndex: "project_name",
            key: "project_name",
            width: "20%",
            ellipsis: {
                showTitle: false,
            },
        },
        {
            title: (
                <div className="flex justify-between items-center">
                    Trạng thái
                    <CustomPopover
                        content={<>
                            <Select
                                options={[
                                    { label: LabelStateProject.STATE_PROCESSING, value: StateProject.STATE_PROCESSING },
                                    { label: LabelStateProject.STATE_APPROVE, value: StateProject.STATE_APPROVE },
                                    { label: LabelStateProject.STATE_REJECT, value: StateProject.STATE_REJECT }
                                ]}
                                allowClear
                                onChange={(value) => setSearchState(value)}
                                style={{ width: "100%" }}
                                placeholder="Chọn trạng thái"
                            />
                        </>}
                        title="Tìm kiếm theo trạng thái"
                        icon={faMagnifyingGlass}
                        placement="bottomRight"
                    />
                </div>
            ),
            dataIndex: "state",
            key: "state",
            width: "10%",
            align: "center",
            render: (_, { state }) => (
                <Tag color={state === StateProject.STATE_APPROVE ? "#87d068"
                    : (state === StateProject.STATE_REJECT ? "#f50" : "#108ee9")}
                >
                    {state === StateProject.STATE_APPROVE ? "Chấp thuận"
                        : (state === StateProject.STATE_REJECT ? "Từ chối" : "Chờ duyệt")}
                </Tag>
            )
        },
        {
            title: "Lí do từ chối",
            dataIndex: "reject_reason",
            key: "reject_reason",
            width: "20%",
        },
        {
            title: "Thao tác",
            dataIndex: "actions",
            key: "actions",
            align: "center",
            width: "10%",
            render: (_, record) => (
                <div className="flex items-center gap-[8px] justify-center">
                    <Tooltip title="Chấp thuận">
                        <Button
                            type="primary"
                            style={{ backgroundColor: "#87d068" }} size="small"
                            disabled={record.state === StateProject.STATE_APPROVE}
                            onClick={(e) => {
                                e.stopPropagation();
                                showModalConfirm(record, "approve");
                            }}
                        >
                            <FontAwesomeIcon icon={faCircleCheck} fontSize={16} />
                        </Button>
                    </Tooltip>
                    <Tooltip title="Từ chối">
                        <Button
                            type="primary"
                            style={{ backgroundColor: "red" }} size="small"
                            disabled={record.state === StateProject.STATE_REJECT}
                            onClick={(e) => {
                                e.stopPropagation();
                                showModalConfirm(record, "reject");
                            }}
                        >
                            <FontAwesomeIcon
                                icon={faCircleXmark}
                                fontSize={16}
                            />
                        </Button>
                    </Tooltip>
                </div>
            )
        },
    ];

    const getAllEntities = () => {
        dispatch(getAllProject({
            offset: tableParams.pagination?.current && ((tableParams.pagination?.current - 1) * (tableParams.pagination?.pageSize ?? 10)),
            limit: tableParams.pagination?.pageSize,
            companyName: searchCompany,
            projectName: searchProject,
            state: searchState
        }))
    };

    const onChangePagination: PaginationProps['onChange'] = (page, pageSize) => {
        setTableParams({
            ...tableParams,
            pagination: {
                current: page,
                pageSize: pageSize
            }
        })
    };

    const onChangeReason = (value: string) => {
        setErrorReject(!(value.length > 0));
        setRejectReason(value);
    };

    const onSubmitConfirm = () => {
        const params = {
            id: projectSelect?.project_id,
            state: type === "reject" ? StateProject.STATE_REJECT : StateProject.STATE_APPROVE,
            reason: rejectReason
        };
        let error = false;
        if (type === "reject") {
            if (!(rejectReason.length > 0)) {
                setErrorReject(true);
                error = true;
            }
        }
        if (!error) {
            dispatch(confirmProject(params)).then((res) => {
                message.open({ type: "success", content: res.payload.message, key, duration: 2 });
                closeModalConfirm();
                getAllEntities();
            }).catch((error) => {
                message.open({ type: "error", content: error.message, key, duration: 2 });
            })
        }
    }

    useEffect(() => {
        getAllEntities();
    }, [JSON.stringify(tableParams), debouncedCompany, debouncedProject, searchState]);

    return (
        <div className="h-full">
            <TitlePage title="Quản lý bài đăng dự án" />
            <div style={{ height: "calc(100% - 106px)" }}>
                <div className="custom-table h-full overflow-auto">
                    <Table
                        columns={columns}
                        dataSource={projectSlice.data}
                        size="small"
                        rowKey={record => record.project_id}
                        pagination={false}
                        onRow={(record) => ({
                            onClick: () => navigate(`${Epath.PROJECT_MANAGER_UPDATE}/${record.project_id}`),
                        })}
                    />
                </div>
                <div className="h-[56px] flex items-center justify-end px-[20px]">
                    <Pagination
                        total={projectSlice.totalRecord}
                        defaultCurrent={1}
                        pageSizeOptions={[10, 20, 30, 50]}
                        showSizeChanger={true}
                        onChange={onChangePagination}
                    />
                </div>
            </div>
            <Modal
                title={`${type === "approve" ? "Duyệt bài đăng" : "Từ chối bài đăng"}`}
                open={modalReject}
                onCancel={closeModalConfirm}
                onOk={onSubmitConfirm}
                width={400}
                okText="Lưu"
                cancelText="Hủy bỏ"
            >
                {type === "reject"
                    ? <>
                        <h4 className="text-center my-[30px]">Vui lòng nhập lý do từ chối bài đăng</h4>
                        <TextArea
                            value={rejectReason}
                            rows={4}
                            placeholder="Lý do..."
                            status={errorReject ? 'error' : undefined}
                            onChange={(e) => { onChangeReason(e.target.value) }}
                        />
                        {errorReject && <p className='text-[red] mt-[4px] text-[12px]'>Yêu cầu nhập lý do</p>}
                    </>
                    : <h4 className="text-center my-[30px]">Xác nhận duyệt bài đăng</h4>
                }
            </Modal>
        </div>
    )
};

export default ProjectManager;