export const Epath = {
    LOGIN: '/login',
    HOME: '/',
    PROFILE: '/profile',
    PROJECT_MANAGER_UPDATE: '/project',
    JOB: '/jobs',
    JOB_MANAGER_UPDATE: '/job',
    PRODUCT: '/products',
    PRODUCT_MANAGER_UPDATE: '/product',
    MESSENGERS: '/messengers',
}