import React, { useEffect, useRef, useState, KeyboardEvent } from "react";
import "./style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane, faSearch } from "@fortawesome/free-solid-svg-icons";
import ItemMessenger from "../../components/atoms/itemMessenger";
import logo from "../../assets/images/error-img.png";
import TextareaAutosize from "react-textarea-autosize";
import { useAppDispatch, useAppSelector } from "../../store/hook";
import { TConversation, getListMessage, getUserChat } from "../../store/features/chatSlice";
import { useParams } from "react-router-dom";
import ItemChat from "../../components/atoms/itemChat";

const Messengers = () => {
   const dispatch = useAppDispatch();
   const userChat = useAppSelector(state => state.chatSlice.userChat);
   const listMessage = useAppSelector(state => state.chatSlice.listMessage);
   const [userChatActive, setUserChatActive] = useState<TConversation>();
   const { conversation_id } = useParams();
   const refListMessage = useRef<HTMLDivElement>(null);

   const loadData = () => {
      dispatch(getUserChat());
   };

   const loadListMessage = () => {
      if (conversation_id) {
         dispatch(getListMessage(conversation_id));
      }
   };

   const scrollToBottom = () => {
      if (refListMessage.current) {
         refListMessage.current.scrollTop = refListMessage.current.scrollHeight;
      }
   };

   const sendMessage = () => {

   };

   const onKeySendChat = (event: KeyboardEvent<HTMLTextAreaElement>) => {
      if (event.key === 'Enter' && !event.shiftKey) {
         event.preventDefault();
         console.log('Sending message...');
      }
   };

   useEffect(() => {
      loadData();
   }, []);

   useEffect(() => {
      if (conversation_id) {
         loadListMessage();
      }

      if (conversation_id && userChat.length > 0) {
         setUserChatActive(userChat.filter((item) => item.key === conversation_id)[0]);
      }
   }, [conversation_id, userChat]);

   useEffect(() => {
      if (listMessage && refListMessage.current) {
         scrollToBottom();
      }
   }, [listMessage]);

   return (
      <div className="container-messenger w-[100%] h-[100%] flex box-border">
         <div className="list-conversation box-border w-[25%] h-[100%]">
            <h3 className="header-conversation p-[10px] h-[45px] box-border">Đoạn chat</h3>
            <div className="search w-[100%] h-[45px] box-border">
               <div className="flex h-[30px] items-center relative w-[100%] px-[10px] box-border">
                  <FontAwesomeIcon
                     className="absolute left-[22px]"
                     icon={faSearch}
                  />
                  <input
                     className="field w-[100%] h-[100%] rounded-[20px] pl-[30px] pr-[15px] shadow-lg"
                     name="search"
                     placeholder="Tìm kiếm tin nhắn"
                  />
               </div>
            </div>
            <div className="list-item-conversation box-border overflow-y-auto overflow-x-hidden h-[100%] px-[8px] mb-[20px]">
               {userChat.map((item) => (
                  <ItemMessenger
                     key={item.key}
                     keyChat={item.key}
                     sender={item.sender}
                     senderName={item.senderName}
                     senderImg={item.senderImg}
                     receiver={item.receiver}
                     receiverName={item.receiverName}
                     receiverImg={item.receiverImg}
                     message={item.message}
                     sendTime={item.sendTime}
                     read={item.read}
                     userLastMessage={item.userLastMessage}
                  />
               ))}
            </div>
         </div>
         <div className="bg-[#DFE1F6] flex-1 h-[100%] relative">
            {conversation_id ? (
               <div className="h-[100%] flex flex-col">
                  <div className="h-[52px] bg-white flex items-center px-[12px]">
                     <img
                        alt="avatar"
                        src={userChatActive?.senderImg ?? logo} width={34} height={34}
                        className="rounded-[50%] object-cover"
                     />
                     <div className="font-semibold text-[13px] ml-[12px]">{userChatActive?.senderName}</div>
                  </div>
                  <div ref={refListMessage} className="flex-1 w-full overflow-auto box-border p-[10px]">
                     {listMessage?.map((item) => (
                        <ItemChat
                           key={item.id}
                           id={0}
                           message={item.message}
                           sendTime={item.sendTime}
                           sender={item.sender}
                           read={item.read}
                        />
                     ))}
                  </div>
                  <div className="min-h-[52px] h-auto box-border flex justify-between items-end gap-[12px] py-[10px] px-[15px] w-full bg-white">
                     <div className="field-chat">
                        <TextareaAutosize
                           className="textarea"
                           placeholder="Soạn tin nhắn..."
                           minRows={1}
                           maxRows={5}
                           onKeyDown={onKeySendChat}
                        />
                     </div>
                     <FontAwesomeIcon fontSize={20} color="blue" icon={faPaperPlane} className="cursor-pointer mb-[10px]" />
                  </div>
               </div>
            ) : (
               <div className="h-full w-full flex items-center justify-center text-[18px] font-semibold italic text-slate-800">
                  Hãy chọn 1 hội thoại với người dùng.
               </div>
            )}
         </div>
      </div>
   )
};

export default Messengers;